<template>
  <div>
    <div style="width:300px; margin: 20px auto">
      <div
        class="dx-field"
        style="text-align:center;margin:20px auto 0 auto;width:200px;"
      >
        <v-row>
          <v-col
            cols="6"
          >
            <input
              v-model="printQrCount"
              type="number"
              style="border:1px solid white;width:80px; height:38px;color:white;"
            >
          </v-col>
          <v-col
            cols="6"
          >
            <DxButton
              text="프린트"
              type="danger"
              width="110"
              @click="onPrintQrcode($event)"
            />
          </v-col>
        </v-row>
      </div>
    </div>
    <div style="margin-top:10px;">
      <dx-data-grid
        :data-source="zebraInfo"
        :show-borders="true"
        :show-row-lines="false"
        :show-column-lines="true"
        :hover-state-enabled="true"
        :allow-column-resizing="true"
        column-resizing-mode="widget"
        :allow-column-reordering="true"
        :height="200"
        :row-alternation-enabled="true"
        :focused-row-enabled="true"
        :auto-navigate-to-focused-row="false"
        :focused-row-key.sync="focusedRowKey"
        key-expr="seqId"
        @focused-row-changed="onSelectionChangedZebra"
        @row-updated="onRowUpdated"
        @row-updating="onRowUpdating"
      >
        <DxColumnFixing :enabled="true" />
        <DxEditing
          :allow-deleting="false"
          :allow-updating="true"
          :allow-adding="false"
          :use-icons="true"
          mode="row"
        />
        <DxColumn
          :allow-editing="false"
          caption="프린터명"
          data-field="프린터명"
          :visible="true"
          :min-width="80"
        />
        <DxColumn
          :allow-editing="true"
          caption="IP"
          data-field="프린터주소"
          :visible="true"
          :min-width="160"
        />
        <DxColumn
          width="60"
          :buttons="editButtons"
          type="buttons"
        />
        <dx-paging :enabled="false" />
        <dx-scrolling
          mode="virtual"
          show-scrollbar="always"
          :use-native="true"
        />
        <dx-sorting mode="multiple" />
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
import ConstDef from '../../../BnhMesVueWin/src/share/ConstDef'
import AppLib from '../share/AppLib'
import DxDataGrid, {
  DxColumnFixing, DxEditing,
  DxScrolling, DxColumn, DxPaging, DxSorting
} from 'devextreme-vue/data-grid'
import { DxButton } from 'devextreme-vue'
// import AesCrypto from '../../share/service/AesCrypto'
import HttpService from '../share/service/HttpService'

export default {
  name: 'Login',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSorting,
    DxScrolling,
    DxButton,
    DxColumnFixing,
    DxEditing
  },
  props: {
    qrOptions: {
      type: Object,
      default: null
    }
  },
  data: function () {
    return {
      userInfo: null,
      popupQrcode: false,
      printQrCount: 10,
      // zebra info
      zebraInfo: null,
      selectedZebraInfo: null,
      focusedRowKey: null,
      editButtons: ['edit']
    }
  },
  computed: {
    getQrOptions () {
      return this.qrOptions
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === null) {
      this.$router.push('/')
      return
    }
    this.refreshZebraInfo()
  },
  mounted () {
  },
  updated () {
  },
  beforeDestroy: function () {

  },
  methods: {
    refreshZebraInfo () {
      this.$_sp.runNoEncodeFindSqlProc('프린터설정', null)
        .then((data) => {
          this.zebraInfo = this.$_sp.MakeModel(data)
          if (this.zebraInfo.length === 1) {
            this.focusedRowKey = this.zebraInfo[0].seqId
          }
        })
        .catch(error => {
          this.Loading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    // qr code popup *********
    onPrintQrcode (e) {
      if (this.selectedZebraInfo === null) {
        this.$snotify.info('출혁 할 프린터 정보를 선택하여 주세요.')
        return
      }
      this.$snotify.confirm(`${this.selectedZebraInfo.프린터명}로 (${this.printQrCount}매) 프린팅 하시겠습니까?`, '', {
        timeout: 10000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          {
            text: '프린트',
            action: (toast) => {
              this.$snotify.remove(toast.id)
              this.printQrcode(this.printQrCount)
            }
          },
          {
            text: '취소',
            action: (toast) => { this.$snotify.remove(toast.id) }
          }
        ]
      })
    },
    async printQrcode (count) {
      this.Loading = true
      const param = {
        count: count,
        createid: this.userInfo.userName,
        createtime: AppLib.getNow()
      }
      await this.$_sp.runUpdateProc('spCreateGoodsQrCodeSerialNo', [param])
        .then((data) => {
        })
        .catch(error => {
          this.Loading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
      if (this.Loading === false) {
        this.$snotify.error('QR Code 발행 요청 실패하였습니다.')
        return
      }
      delete param.count
      let qrList = null
      await this.$_sp.runNoEncodeFindProc('spFindAllInputQrcode', param)
        .then((data) => {
          qrList = this.$_sp.MakeModel(data)
          console.log(qrList)
        })
        .catch(error => {
          this.Loading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
      if (this.Loading === false) {
        this.$snotify.error('QR Code 생성 요청 실패하였습니다.')
        return
      }
      // TODO QRCODE Print 요청
      const printList = []
      const year = AppLib.getToday().substring(0, 4)
      for (let i = 0; i < qrList.length; i++) {
        const base64 = AppLib.getBase64SameLength(`${qrList[i].seqId}${year}`)
        const zpl = this.mkaeZpl(base64, `${qrList[i].seqId}${year}`)
        const temp = {
          지역: this.selectedZebraInfo.지역,
          프린터명: this.selectedZebraInfo.프린터명,
          프린터주소: this.selectedZebraInfo.프린터주소,
          포워딩포트: this.selectedZebraInfo.포워딩포트,
          qrCode: base64,
          문자: `${qrList[i].seqId}${year}`,
          문자2: null,
          장수: 1,
          완료: !ConstDef.QR_PRINT,
          zpl: zpl,
          사용유무: 0,
          createid: this.userInfo.userName,
          updateid: this.userInfo.userName
        }
        printList.push(temp)
      }
      await this.$_sp.runInsertSqlProc('프린팅대기', null, printList)
        .then((data) => {
          this.$snotify.info('QR Code 프린팅 요청하였습니다.')
          HttpService.reqPost(JSON.stringify({ printName: this.selectedZebraInfo.프린터명 }), '/qrCodePrinting')
        })
        .catch(error => {
          this.Loading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
      this.Loading = false
    },
    onRowUpdated (row) {
      const param = {
        seqId: row.data.seqId,
        프린터주소: row.data.프린터주소
      }
      this.$_sp.runUpdateSqlProc('프린터설정', ['seqId'], null, [param])
        .then((data) => {
          this.$snotify.info('변경 되었습니다.')
        })
    },
    onRowUpdating (row) {
      if (!AppLib.ValidateIp(row.newData.프린터주소)) {
        this.$snotify.info('ip 주소 형식이 아닙니다.')
        row.cancel = true
      }
    },
    mkaeZpl (qrCode, text) {
      let zpl = ConstDef.PALLET_NO_ZPL
      zpl = AppLib.replaceAll(zpl, '{{QR_CODE}}', qrCode)
      zpl = AppLib.replaceAll(zpl, '{{TITLE}}', text)
      return zpl
    },
    // zebran grid
    onSelectionChangedZebra (e) {
      if (e != null && e.row === undefined) {
        return
      }
      if (e !== null) {
        this.selectedZebraInfo = e.row.data
      }
    }

  }
}
</script>

<style lang="scss">
.ff{
  text-align: center;
}
</style>
