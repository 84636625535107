<template>
  <v-container>
    <SerailNoQrPrintComponent />
    <loading
      :active.sync="isLoading"
      color="rgb(2, 110, 156)"
      loader="bars"
    />
  </v-container>
</template>
<script>
import Loading from 'vue-loading-overlay'
import SerailNoQrPrintComponent from '../../components/SerailNoQrPrintComponent'

export default {
  name: 'PalletQRPrint',
  components: {
    Loading,
    SerailNoQrPrintComponent
  },
  props: {
    type: {
      type: String,
      default: '0'
    }
  },
  data: function () {
    return {
      isLoading: false,
      userInfo: null,
      goodsInfoList: [],
      gridHeight: null,
      // popup
      qrOptions: null,
      popupQrcode: false,
      qrCodePopupTitle: null
    }
  },
  computed: {
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
    }
  },
  mounted () {
    this.gridHeight = (this.$Q('html').height() - 90) - 30
    this.$Q('#p-code').focus()
  },
  updated () {
  },
  methods: {
  }
}
</script>

<style lang="scss">
  .data-grid {
    margin-top:0px;
  }
  .qrcode-popup-wrap {
    .dx-popup-content {
      background-color: rgb(165, 164, 164)!important;
    }
  }
</style>
